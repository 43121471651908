<template>
    <div>
        <mobile-layout tab="0">
            <!-- <v-img src="../../assets/bg.jpeg"></v-img> -->
        </mobile-layout>

        <v-dialog v-model="dialog" width="500">
            <v-card
                :style="{
                    background:
                        'url(' + require('../../assets/dialog_bg.png') + ')',
                    backgroundSize: '100% 100%',
                }"
                height="80vh"
            >
                <div style="padding-top: 15vh"></div>
                <v-card-text
                    style="
                        height: 56vh;
                        overflow-y: scroll;
                        padding: 0;
                        margin: 0 auto;
                        width: 93%;
                        background: linear-gradient(#b5e7ff, #e5feff);
                    "
                >
                    <v-img :src="url"></v-img>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col cols="6" offset="3">
                            <v-btn
                                color="white"
                                style="color: #006fae"
                                @click="dialog = false"
                                block
                                tile
                                depressed
                            >
                                我已知晓
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import MobileLayout from '../../components/MobileLayout.vue'
export default {
    components: { MobileLayout },
    name: 'Index',

    data: () => ({
        dialog: true,
        url: '',
    }),

    created () {
        this.getData()
    },

    methods: {
        open(url) {
            window.open(url, '_blank')
        },
        getData() {
            this.axios.get('api/setting?name=remark').then((response)=>{
                this.url = response.data.value
            }).catch((response)=>{
                console.log(response)
            })
        }
    },
}
</script>

<style lang="scss" scoped></style>
